import React from 'react'
import { StickyProvider } from 'contexts/app/app.provider'
import SEO from 'components/seo'
import Layout from 'components/layout'

// Load other package css file
import 'react-multi-carousel/lib/styles.css'
import 'react-modal-video/css/modal-video.min.css'
import 'rc-drawer/assets/index.css'
// Custom css style
import 'assets/css/style.css'
// Plugins css style
import 'assets/plugins/bootstrap/bootstrap.min.css'
import 'assets/plugins/themify-icons/themify-icons.css'
import 'assets/plugins/slick/slick.css'
import 'assets/plugins/slick/slick-theme.css'
import 'assets/plugins/fancybox/jquery.fancybox.min.css'
import 'assets/plugins/aos/aos.css'

export default function PrivacyPolicyPage() {
    return (
        <StickyProvider>
            <Layout>
                <SEO title="Politique de confidentialité" />
                <section class="section page-title">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-8 m-auto">
                                <h1>Politique de confidentialité</h1>
                                <p>(<em>Dernière mise à jour le 17 mai 2022 à 00h25'</em>)</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="privacy section pt-0">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="block">
                                    <p>
                                        Bienvenue sur la plateforme NiWapi qui est une plateforme de marketing digital et
                                        un canal de communication de NiWapi spécialisée dans la diffusion et la promotion
                                        de l’information locale en République Démocratique du Congo.
                                    </p>
                                    <p>
                                        Nous respectons votre vie privée et nous gardons jalousement vos informations.
                                    </p>
                                    <p>
                                        Sous ce point, nous vous présentons comment se fait la collecte de vos informations
                                        et comment, sous certaines conditions, se fait leur divulgation.
                                    </p>
                                    <p>
                                        La protection des données est notre priorité, vos données sont d’une grande
                                        importance à nos yeux ; pour cela nous n’utiliserons vos informations que dans le
                                        sens décrit dans cette politique de confidentialité.
                                    </p>
                                    <p>
                                        Les informations que nous prélevons comprennent et ne se limitent qu’aux : noms,
                                        photo de profil, numéro de téléphone, adresse mail, compte facebook, tranche d’age,
                                        sexe, pays, province et ville où vous êtes situé, et préférences sur les activités qui
                                        vous intéressent, lesquelles sont relevés au travers de vos “like” et vos favoris.
                                    </p>
                                    <p>
                                        Nous recueillons des informations à partir des téléphones et ordinateurs connectés à
                                        notre plateforme via l’application mobile et le site web que vous utilisez et qui
                                        s’intègrent à nos produits.
                                    </p>
                                    <p>
                                        Au travers de ces outils informatiques, il y a une collecte d’informations que vous
                                        nous autorisez de recevoir via les paramètres de l’appareil que vous activez, comme
                                        l’accès à votre position GPS, votre appareil photo ou à vos photos de galerie, vos
                                        informations telles que la langue, le fuseau horaire, etc. Ces données sont utiles
                                        entre autres pour : la géolocalisation des établissements et des événements enregistrés
                                        ou à enregistrer sur notre plateforme, le téléversement de la photo de profil, la mise
                                        à disposition de la langue utilisée par téléphone sur l’application mobile.
                                    </p>
                                    <h4>Comment utilisons-nous les informations collectées ?</h4>
                                    <p>
                                        Nous utilisons les informations dont nous disposons comme décrit ci-dessous et pour
                                        fournir et prendre en charge les produits de NiWapi et les services associés. Voici
                                        comment:
                                        <ol>
                                            <li>
                                                Identifier, Fournir, personnaliser et améliorer nos produits
                                                <p>
                                                    Nous utilisons ces informations pour pouvoir procéder à votre identification, pour
                                                    personnaliser les fonctionnalités (adapter nos services à votre profil et votre milieu),
                                                    pour adapter le contenu et vous faire des suggestions (comme des établissements ou
                                                    des événements susceptibles de vous intéresser) au travers de nos produits.
                                                    <br />
                                                    Pour créer des produits personnalisés qui sont uniques et pertinents pour vous, nous
                                                    utilisons vos connexions, préférences, centre d’intérêts et activités en fonction des
                                                    données que nous collectons et apprenons de vous et des autres.
                                                </p>
                                            </li>
                                            <li>
                                                Administrer votre compte
                                                <p>
                                                    Ces informations nous permettent d’administrer votre compte voire d’engager des
                                                    transactions (ou des paiements) faites sur notre plateforme.
                                                </p>
                                            </li>
                                            <li>
                                                Fournir des mesures, des analyses et d’autres services commerciaux
                                                <p>
                                                    Nous utilisons les informations dont nous disposons pour aider les annonceurs et
                                                    autres partenaires à mesurer l’efficacité et la distribution de leurs publicités et
                                                    services, et à comprendre les types de personnes qui utilisent leurs services et
                                                    comment les gens interagissent avec ces services-là. Pour se faire, nous proposons sous la 								plateforme des fonctionnalités de vue, de like, de favoris, de followers, de following 								et de regroupement d’utilisateurs. Dans le meme sens, des fonctionnalités 								supplémentaires seront implémentées au fil du temps. 
                                                </p>
                                            </li>
                                            <li>
                                                Assurer la sûreté, l’intégrité et la sécurité
                                                <p>
                                                    Nous utilisons les informations dont nous disposons pour vérifier les comptes et
                                                    l’activité, lutter contre les activités préjudiciables, détecter et prévenir les spams et
                                                    autres mauvaises expériences, maintenir l’intégrité de nos produits et assurer la
                                                    sécurité de vos données et des produits NiWapi.
                                                </p>
                                            </li>
                                            <li>
                                                Communiquer avec vous
                                                <p>
                                                    Nous utilisons les informations dont nous disposons pour vous envoyer des
                                                    communications marketing, communiquer avec vous sur nos produits et vous
                                                    informer de nos politiques et conditions. Nous utilisons également vos informations
                                                    pour vous répondre lorsque vous nous contactez.
                                                </p>
                                            </li>
                                        </ol>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </StickyProvider>
    );
}
